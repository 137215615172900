import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import HyperCardMini from "../../components/hyper-card-mini"
import Image from "../../components/image"
import NoHyphen from "../../components/no-hyphen"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const Mediaguide = () => {
  const data = useStaticQuery(graphql`
    query MediaguideQuery {
      teaser1: file(relativePath: { eq: "mediaguide/teaser-1.jpg" }) {
        ...largeImage
      }
      teaser2: file(relativePath: { eq: "mediaguide/teaser-2.jpg" }) {
        ...largeImage
      }
      audio: file(relativePath: { eq: "mediaguide/audio.jpg" }) {
        ...smallImage
      }
      leichteSprache: file(
        relativePath: { eq: "mediaguide/leichte-sprache.jpg" }
      ) {
        ...smallImage
      }
      gebaerdensprache: file(
        relativePath: { eq: "mediaguide/gebaerdensprache-3.jpg" }
      ) {
        ...smallImage
      }
      english: file(relativePath: { eq: "mediaguide/english.jpg" }) {
        ...smallImage
      }
      french: file(relativePath: { eq: "mediaguide/french.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <Layout backdrop="mediaguide">
      <Seo
        title="Media-Guide"
        description="Mit dem Media-Guide erfahren Sie spannende Hintergründe zu den Objekten und Themen der Dauerausstellung. Der Media-Guide ist inklusiv: Er enthält Inhalte in Leichter Sprache und Gebärdensprache, Angebote für blinde und sehbeeinträchtigte Menschen und in den Sprachen Deutsch, Englisch und Französisch."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Media-Guide",
                link: "/mediaguide",
              },
            ]}
          />
          <PageTitle>Media-Guide zur Dauerausstellung</PageTitle>
          <Paragraph dropcap={true}>
            Mit unserem Media-Guide erfahren Sie spannende Hintergründe zu den
            Objekten und Themen der Dauerausstellung. Der{" "}
            <NoHyphen>Media-Guide</NoHyphen> ist inklusiv: Er enthält Inhalte in{" "}
            <TextLink to="/mediaguide/leichte-sprache">
              Leichter Sprache
            </TextLink>{" "}
            und{" "}
            <TextLink to="/mediaguide/gebaerdensprache">
              Gebärdensprache
            </TextLink>
            , Angebote für{" "}
            <TextLink to="/mediaguide/blinde-sehbeeintraechtigte">
              blinde und sehbeeinträchtigte Menschen
            </TextLink>{" "}
            und in den Sprachen Deutsch,{" "}
            <TextLink to="/mediaguide/english">Englisch</TextLink> und{" "}
            <TextLink to="/mediaguide/francais">Französisch</TextLink>.
          </Paragraph>
          <Grid columns={[1, 2]} space={"px"}>
            <Image
              image={data.teaser1.childImageSharp.gatsbyImageData}
              alt="Eine Besucherin beim Verwenden des Media-Guide mit Inhalten in Gebärdensprache"
            />
            <Image
              image={data.teaser2.childImageSharp.gatsbyImageData}
              alt="Die Audioinhalte des Media-Guide"
            />
          </Grid>
          <Paragraph>
            Mit dem Media-Guide kann sich jeder selbst einen Weg durch die
            Ausstellung zusammenstellen: Raum für Raum. Oder von einem Objekt
            zum nächsten.{" "}
          </Paragraph>
          <Paragraph>
            Den <NoHyphen>Media-Guide</NoHyphen> kann man über die{" "}
            <TextLink to="/literaturpfad">Tübinger Literaturpfad-App</TextLink>{" "}
            herunterladen oder an der Museumskasse ausleihen.
          </Paragraph>
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={5}>
            Alle Angebote im Überblick
          </Heading>
          <HyperCardMini
            headingElement="h3"
            image={data.leichteSprache}
            title="Leichte Sprache"
            to="/mediaguide/leichte-sprache"
            description="Sie können eine Führung in Leichter Sprache anhören. Sie führt durch die ganze Dauer∙ausstellung. Im Media∙Guide finden Sie auch alle Ausstellungs∙Texte als Lese∙Texte in Leichter Sprache."
          />
          <HyperCardMini
            headingElement="h3"
            image={data.gebaerdensprache}
            title="Gebärdensprache"
            to="/mediaguide/gebaerdensprache"
            description="In 15 Videos in Deutscher Gebärdensprache erfahren Sie mehr über die Ausstellungsräume und -themen."
          />
          <HyperCardMini
            headingElement="h3"
            image={data.audio}
            title="Angebote für blinde und sehbeeinträchtigte Menschen"
            to="/mediaguide/blinde-sehbeeintraechtigte"
            description="Im Museum können Sie sich einen Tastleitplan und ein Gerät mit taktilen Tasten ausleihen. Über eine Hör-Führung erhalten Sie Beschreibungen und Navigationshilfen zu den Ausstellungsräumen. Und Sie erhalten Audiodeskriptionen zu ausgewählten Ausstellungsstücken."
          />
          <HyperCardMini
            headingElement="h3"
            image={data.english}
            title="English"
            to="/mediaguide/english"
            description="The media-guide for our permanent exhibition contains an an english audio-tour and english translations for all texts in the exhibition."
          />
          <HyperCardMini
            headingElement="h3"
            image={data.french}
            title="Français"
            to="/mediaguide/francais"
            description="ll y a un média-guide pour l'exposition permanente. Il contient une visite audio en français et des traductions en français de tous les textes de l'exposition."
          />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Mediaguide
